import React from "react"
import HeroInner from "../components/heroinner"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Row} from 'react-bootstrap'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeroInner title="404"/>
    <Row className="justify-content-center mt-5">
      <h1>404: Not Found</h1>
    </Row>
    <Row className="justify-content-center mb-5">
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </Row>
  </Layout>
)

export default NotFoundPage
